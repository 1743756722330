@import '~antd/es/style/themes/default.less';
@import '~antd/dist/antd.dark.less';

.ant-pro-table-list-toolbar-container {
  padding: @padding-md;
}
.ant-pro-table-list-toolbar-setting-item {
  color: @text-color;
}
.ant-descriptions-item-container .ant-descriptions-item-label {
  color: @text-color;
}
.ant-table-pagination.ant-pagination {
  padding: 0 @padding-md;
}
.ant-card {
  border: @border-width-base @border-style-base @border-color-base;
}
.ant-pro-table-search {
  border-radius: @border-radius-base;
  border: @border-width-base @border-style-base @border-color-base;
}
.ant-image-img  {
  border-radius: @border-radius-base;
}

@primary-color: #116afc;@table-padding-horizontal: 32px;@card-padding-base: 0px;@border-radius-base: 4px;